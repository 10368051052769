import React from "react";
import ProductContent from "./product_content";

function Product() {

    return(
        <ProductContent />
    )
}

export default Product