import React from "react";

function AboutContent() {
    return (

        <div>

<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
										<div class="container px-5">
											<a class="navbar-brand" href="#!">Mercado Escolor</a>
											<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
												<span class="navbar-toggler-icon"/>
											</button>
											<div class="collapse navbar-collapse" id="navbarSupportedContent">
												<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
													<li class="nav-item">
														<a class="nav-link" aria-current="page" href="/">Home</a>
													</li>
													<li class="nav-item">
														<a class="nav-link active" href="#!">About</a>
													</li>
													<li class="nav-item">
														<a class="nav-link" href="/contact">Contact</a>
													</li>
													<li class="nav-item">
														<a class="nav-link" href="/services">Services</a>
													</li>
													<li class="nav-item">
													<a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
													</li>
													<li class="nav-item">
														<a class="nav-link" href="/shop">Shop</a>
													</li>
													<li class="nav-item">
														<a class="nav-link" href="/login">Login/Register</a>
													</li>
												</ul>
											</div>
										</div>
									</nav>
									<header class="bg-dark py-5">
										<div class="container px-5">
											<div class="row gx-5 align-items-center justify-content-center">
												<div class="col-lg-8 col-xl-7 col-xxl-6">
													<div class="my-5 text-center text-xl-start">
														<h1 class="display-5 fw-bolder text-white mb-2">Welcome to Mercado Escolor</h1>
														<p class="lead fw-normal text-white-50 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod tenetur ex natus at dolorem enim! Nesciunt pariatur voluptatem sunt quam eaque, vel, non in id dolore voluptates quos eligendi labore.

</p>
														<div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
															<a class="btn btn-outline-light btn-lg px-4" href="#!">Learn More</a>
														</div>
													</div>
												</div>
												<div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
													<img class="img-fluid rounded-3 my-5" src="images/about-right.png" alt="..."/>
												</div>
											</div>
                                            </div>
										</header>
										<section class="py-5 bg-light" id="scroll-target">
											<div class="container px-5 my-5">
												<div class="col-lg-12">
													<h2 class="fw-bolder">About Us </h2>
													<p class="lead fw-normal text-muted mb-0">Contrary to 
popular belief, Lorem Ipsum is not simply random text. It has roots in a
 piece of classical Latin literature from 45 BC, making it over 2000 
years old. Richard McClintock, a Latin professor at Hampden-Sydney 
College in Virginia, looked up one of the more obscure Latin words, 
consectetur, from a Lorem Ipsum passage, and going through the cites of 
the word in classical literature, discovered the undoubtable source. 
Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus 
Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written 
in 45 BC. This book is a treatise on the theory of ethics, very popular 
during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum 
dolor sit amet..", comes from a line in section 1.10.32.

The standard chunk of Lorem Ipsum used since the 1500s is reproduced 
below for those interested. Sections 1.10.32 and 1.10.33 from "de 
Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
 original form, accompanied by English versions from the 1914 
translation by H. Rackham.</p>
												</div>
											</div>
										</section>

        </div>

    )
    }

export default AboutContent