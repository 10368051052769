import React from "react"
import ContactContent from "./contact_content"


function Contact()
{
    return (
        <ContactContent />
    )
}

export default Contact