import React from "react";

function AdminContent() {

    return(
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container px-5">
            <a className="navbar-brand" href="#!">Mercado Escolor</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link" aria-current="page" href="Home.html">Home</a></li>
                <li className="nav-item"><a className="nav-link " href="About.html">About</a></li>
                <li className="nav-item"><a className="nav-link" href="Contact.html">Contact</a></li>
                <li className="nav-item"><a className="nav-link" href="Services.html">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="Shop.html">Shop</a></li>
                <li className="nav-item"><a className="nav-link active" href="#">Login/Register</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <section style={{backgroundColor: '#eee'}}>
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">Admin Profile</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <img src="images/avatar.png" alt="avatar" className="rounded-circle img-fluid" style={{width: '150px'}} />
                    <h5 className="my-3">Admin</h5>
                    <p className="text-muted mb-4">United States </p>
                    <div className="d-flex justify-content-center mb-2">
                      <button type="button" className="btn btn-primary">Edit Profile</button>
                      <button type="button" className="btn btn-outline-primary ms-1">Log Out</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="card mb-4 mb-lg-0">
                    <div className="card-body p-0">
                      <ul className="list-group list-group-flush rounded-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Schools</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Users</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">User Queries</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">System Status</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Reports</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card text-white bg-info">
                          <h5 className="card-header">
                            Manage Schools
                          </h5>
                          <div className="card-body">
                            <p className="card-text" style={{textAlign: 'center'}}>
                              <img alt="#" src="images/school.png" width={200} height={150} />
                            </p>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary">Manage</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <h5 className="card-header">
                            Manage Users
                          </h5>
                          <div className="card-body">
                            <p className="card-text" style={{textAlign: 'center'}}>
                              <img alt="#" src="images/users.png" width={200} height={155} />
                            </p>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary">Manage</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card text-white bg-primary">
                          <h5 className="card-header">
                            User Queries
                          </h5>
                          <div className="card-body">
                            <p className="card-text" style={{textAlign: 'center'}}>
                              <img alt="#" src="images/user_query.png" width={200} height={150} />
                            </p>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-success">Manage</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <h5 className="card-header">
                            Reports
                          </h5>
                          <div className="card-body">
                            <p className="card-text" style={{textAlign: 'center'}}>
                              <img alt="#" src="images/reports.png" width={200} height={155} />
                            </p>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary">Manage</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div>
        </section>
      </div>
    )
}

export default AdminContent