import React from "react";
import Footer from "../main/footer";
import AboutContent from "./about_content";

function About() {
    return (
        <><AboutContent /><Footer /></>
    )

    }
export default About