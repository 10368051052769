import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home  from './components/Home/home_page';
import About from './components/Aboutus/about_page';
import Contact from './components/contact/contact_page';
import Services from './components/Services/services_page';
import Auth from './components/auth/auth_page';
import Shop from './components/shop/shop_page';
import Student from './components/Student/student_page';
import Supplier from './components/Supplier/supplier_page';
import Admin from './components/admin/admin_page';
import SchoolAdmin from './components/SchoolAdmin/school_admin_page';
import Product from './components/Supplier/Product/product_page';

function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/about" element={<About/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="/services" element={<Services/>}/>
      <Route exact path="/shop" element={<Shop/>}/>
      <Route exact path="/login" element={<Auth/>}/>
      <Route exact path="/student" element={<Student/>}/>
      <Route exact path="/supplier" element={<Supplier/>}/>
      <Route exact path="/admin" element={<Admin/>}/>
      <Route exact path="/schooladmin" element={<SchoolAdmin/>}/>
      <Route exact path="/product" element={<Product/>}/>
    </Routes>
    </Router>
  );
}


export default App;
