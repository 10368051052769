import React from "react";
import SupplierContent from "./supplier_content";

function Supplier() {

    return(
        <SupplierContent />
    )


}

export default Supplier