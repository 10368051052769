import React, {useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

function AuthContent() {

  const [startDate, setStartDate] = useState();

  return (

    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container px-5">
          <a className="navbar-brand" href="#!">Mercado Escolor</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item"><a className="nav-link" aria-current="page" href="/">Home</a></li>
              <li className="nav-item"><a className="nav-link" href="/about">About</a></li>
              <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
              <li className="nav-item"><a className="nav-link" href="/services">Services</a></li>
              <li className="nav-item"><a className="nav-link" href="/shop">Shop</a></li>
              <li class="nav-item">
                <a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
              </li>
              <li className="nav-item"><a className="nav-link active" href="#">Login/Register</a></li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container login-container">
        <div className="row">
          <div className="col-md-6 login-form-1">
            <h3 style={{ fontWeight: 600 }}>Sign In</h3>
            <h6 style={{ textAlign: 'center' }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layou</h6>
            <form action="http://localhost/user" method="GET" className="needs-validation" Validate>
              <input type="hidden" name="_token" defaultValue="k6gKQU4resXmyNtQS6KAKY5VawvM6p1SWl7aRszb" />                <div className="form-floating mb-3">
                <input className="form-control" id="email" name="email" type="email" placeholder="Enter your email address..." required />
                <label htmlFor="name">Email address</label>
                <div className="invalid-feedback">A email is required.</div>
              </div>
              <div className="form-floating mb-3">
                <input className="form-control" id="password" name="password" type="password" placeholder="Enter your password..." required />
                <label htmlFor="name">Password</label>
                <div className="invalid-feedback">A password is required.</div>
              </div>
              <div className="form-group">
                <input type="submit" className="btn btn-primary btn-lg" style={{ width: '50%' }} value="Login" >
                  </input>
              </div>
              <div className="form-group">
                <a href="#" className="ForgetPwd">Forget Password?</a>
              </div>
            </form>
          </div>
          <div className="col-md-6 login-form-2">
            <h3>Register</h3>
            <h6 style={{ textAlign: 'center', color: 'white' }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layou</h6>
            <form action="" method="POST">
              <input type="hidden" name="_token" defaultValue="k6gKQU4resXmyNtQS6KAKY5VawvM6p1SWl7aRszb" />                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="fname" name="fname" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                    <label htmlFor="name">First Name</label>
                    <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="lname" name="lname" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                    <label htmlFor="name">Last Name</label>
                    <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <label htmlFor="name">Date Of Birth
                    </label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control"  fixedHeight="false"
                    placeholderText="Date Of Birth"/>
                    <div className="invalid-feedback" data-sb-feedback="name:required">A date of birth is required.</div>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input className="form-control" id="email" name="email" type="text" placeholder="Enter your email address..." data-sb-validations="required" />
                <label htmlFor="name">Email address</label>
                <div className="invalid-feedback" data-sb-feedback="name:required">A email is required.</div>
              </div>
              <div className="form-floating mb-3">
                <input className="form-control" id="email" name="email" type="text" placeholder="Enter your email address..." data-sb-validations="required" />
                <label htmlFor="name">School Name</label>
                <div className="invalid-feedback" data-sb-feedback="name:required">A email is required.</div>
              </div>
              <div className="form-floating mb-3">
                <input className="form-control" id="password" name="password" type="password" placeholder="Enter your password..." data-sb-validations="required" />
                <label htmlFor="name">Password</label>
                <div className="invalid-feedback" data-sb-feedback="name:required">A password is required.</div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label text-white" for="flexRadioDefault1">
                    Student
                  </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                  <label class="form-check-label text-white" for="flexRadioDefault2">
                    Professor
                  </label>
              </div>
              <br />
              <div className="form-group">
                <input type="submit" className="btn btn-primary btn-lg" style={{ width: '50%' }} value="Create" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthContent