import React from "react";
import AuthContent from "./auth_content";

function Auth() {

    return(
        <AuthContent />
    )
}

export default Auth