import React from "react";

function StudentContent() {

    return(
        
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container px-5">
            <a className="navbar-brand" href="#!">Mercado Escolor</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link" aria-current="page" href="Home.html">Home</a></li>
                <li className="nav-item"><a className="nav-link " href="About.html">About</a></li>
                <li className="nav-item"><a className="nav-link" href="Contact.html">Contact</a></li>
                <li className="nav-item"><a className="nav-link" href="Services.html">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="Shop.html">Shop</a></li>
                <li class="nav-item">
                <a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
              </li>
                <li className="nav-item"><a className="nav-link active" href="#">Login/Register</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <section style={{backgroundColor: '#eee'}}>
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">User Profile</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <img src="images/avatar.png" alt="avatar" className="rounded-circle img-fluid" style={{width: '150px'}} />
                    <h5 className="my-3">Test User</h5>
                    <p className="text-muted mb-1">Full Stack Developer</p>
                    <p className="text-muted mb-4">United States </p>
                    <div className="d-flex justify-content-center mb-2">
                      <button type="button" className="btn btn-primary">Edit Profile</button>
                      <button type="button" className="btn btn-outline-primary ms-1">Log Out</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="card mb-4 mb-lg-0">
                    <div className="card-body p-0">
                      <ul className="list-group list-group-flush rounded-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Menu</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Mange Orders</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Joined Club</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Settings</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Full Name</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">Test user</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Email</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">example@example.com</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Phone</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">+12714236749</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Mobile</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">+12714236749</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Address</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">United States</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Address</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">United States</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card mb-4 mb-md-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-9">
                            <p className="mb-4"><span className="text-primary font-italic me-1">Joined recent</span> Clubs
                            </p>
                          </div>
                          <div className="col-md-3">
                            <button type="button" className="btn btn-primary">Create</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div className="card" style={{width: '80px', height: '80px'}}>
                              <img src="images/fitness.png" className="card-img-top" alt="..." />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card" style={{width: '80px', height: '80px'}}>
                              <img src="images/sports.png" className="card-img-top" alt="..." />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card" style={{width: '80px', height: '80px'}}>
                              <img src="images/post11.png"  className="card-img-top" alt="..." />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card" style={{width: '80px', height: '80px'}}>
                              <img src="images/post11.png"  className="card-img-top" alt="..." />
                            </div>
                          </div>
                          <div className="col-3" style={{marginTop: '8px'}}>
                            <div className="card" style={{width: '80px', height: '80px'}}>
                              <img src="images/post11.png"  className="card-img-top" alt="..." />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mb-4 mb-md-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-9">
                            <p className="mb-4"><span className="text-primary font-italic me-1">Recent</span> Posts
                            </p>
                          </div>
                          <div className="col-md-3">
                            <button type="button" className="btn btn-warning">Create </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="well">
                              <img src="images/post11.png" className="img-circle" alt="Avatar" width={55} height={55} />
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <div className="well">
                              <p>Just Forgot that I had to mention something 
                                about someone to someone about how I forgot something, but now I forgot 
                                it. Ahh, forget it! Or wait. I remember.... no I don't.</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="well">
                              <img src="images/post11.png" className="img-circle" alt="Avatar" width={55} height={55} />
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <div className="well">
                              <p>Just Forgot that I had to mention something 
                                about someone to someone about how I forgot something, but now I forgot 
                                it. Ahh, forget it! Or wait. I remember.... no I don't.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

export default StudentContent