import React from "react"

function HomeContent() {
  return (
    <div>

      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container px-5">
          <a class="navbar-brand" href="#!">Mercado Escolor</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" />
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="/about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/services">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/shop">Shop</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/login">Login/Register</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5 align-items-center my-5">
          <div className="col-lg-7">
            <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="images/Slide_01.png" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src="images/Slide_02.png" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src="images/Slide_04.png" className="d-block w-100" alt="..." />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Mercado Escolor</h1>
            <p>A web portal is a specially designed website that brings information from diverse sources, like emails, online forums and search engines, together in a uniform way. Usually, each information source gets its dedicated area on the page for displaying information; often, the user can configure which ones to display.</p>
            <a className="btn btn-primary" href="/shop">Shopping</a>
          </div>
        </div>
        {/* Call to Action*/}
        <div className="card text-white bg-secondary my-5 py-4 text-center">
          <div className="card-body"><p className="text-white m-0">Welcome to new shopping experience ! This is your college marketplace</p></div>
        </div>
        {/* Content Row*/}
        <div className="row gx-4 gx-lg-5">
          <div className="col-md-4 mb-5">
            <div className="card h-100">
              <div className="card-body">
                <h2 className="card-title">IT Management</h2>
                <br />
                <img src="images/computer.png" className="d-block w-64" alt="..." />
                <p className="card-text">Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus
                  quam molestias corporis quod, ea minima accusamus.</p>
              </div>
              <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card h-100">
              <div className="card-body">
                <h2 className="card-title">Software Development</h2>
                <br />
                <img src="images/coding.png" className="d-block w-64" alt="..." />
                <p className="card-text">Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit. Quod tenetur ex natus at dolorem enim!
                  Nesciunt pariatur voluptatem sunt quam eaque, vel, non in id dolore
                  voluptates quos eligendi labore.</p>
              </div>
              <div className="card-footer">
                <a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card h-100">
              <div className="card-body">
                <h2 className="card-title">Product Management</h2>
                <br />
                <img src="images/product-chain.png" className="d-block w-64" alt="..." />
                <p className="card-text">Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus
                  quam molestias corporis quod, ea minima accusamus.</p>
              </div>
              <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-5 justify-content-center">
        <div className="col-lg-8 col-xl-6">
          <div className="text-center">
            <a className="btn btn-primary" href="http://localhost/services">More Services &gt;&gt;</a>
          </div>
        </div>
      </div>
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="text-center">
                <h2 className="fw-bolder">Latest Posts</h2>
                <p className="lead fw-normal text-muted mb-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque fugit ratione dicta mollitia. Officiis ad.</p>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <img className="card-img-top" src="images/post1.png" alt="..." />
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!"><h5 className="card-title mb-3"><b>Announcing if attachment resolution sentiments.</b></h5></a>
                  <p className="card-text mb-0">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center">
                      <img className="rounded-circle me-3" src="images/6c757d.png" alt="..." />
                      <div className="small">
                        <div className="fw-bold">User01</div>
                        <div className="text-muted">March 12, 2022 · 6 min read</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <img className="card-img-top" src="images/post2.png" alt="..." />
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">Media</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!"><h5 className="card-title mb-3"><b>Partiality indulgence dispatched to of celebrated</b></h5></a>
                  <p className="card-text mb-0">This text
                    is a bit longer to illustrate the adaptive height of each card. Some
                    quick example text to build on the card title and make up the bulk of
                    the card's content.</p>
                </div>
                <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center">
                      <img className="rounded-circle me-3" src="images/6c757d.png" alt="..." />
                      <div className="small">
                        <div className="fw-bold">user02</div>
                        <div className="text-muted">March 23, 2022 · 4 min read</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <img className="card-img-top" src="images/post3.png" alt="..." />
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!"><h5 className="card-title mb-3"><b>Minuter him own clothes but observe country</b></h5></a>
                  <p className="card-text mb-0">Some more quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center">
                      <img className="rounded-circle me-3" src="images/6c757d.png" alt="..." />
                      <div className="small">
                        <div className="fw-bold">User03</div>
                        <div className="text-muted">April 2, 2022 · 10 min read</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="text-center">
                <a className="btn btn-primary" href="#">More Posts &gt;&gt;</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeContent