import React from "react";

function SchoolAdminContent() {
    return (
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container px-5">
            <a className="navbar-brand" href="#!">Mercado Escolor</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link" aria-current="page" href="Home.html">Home</a></li>
                <li className="nav-item"><a className="nav-link " href="About.html">About</a></li>
                <li className="nav-item"><a className="nav-link" href="Contact.html">Contact</a></li>
                <li className="nav-item"><a className="nav-link" href="Services.html">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="Shop.html">Shop</a></li>
                <li class="nav-item">
                <a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
              </li>
                <li className="nav-item"><a className="nav-link active" href="#">Login/Register</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <section style={{backgroundColor: '#eee'}}>
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">User Profile</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <img src="images/avatar.png" alt="avatar" className="rounded-circle img-fluid" style={{width: '150px'}} />
                    <h5 className="my-3">School Admin</h5>
                    <p className="text-muted mb-4">United States </p>
                    <div className="d-flex justify-content-center mb-2">
                      <button type="button" className="btn btn-primary">Edit Profile</button>
                      <button type="button" className="btn btn-outline-primary ms-1">Log Out</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="card mb-4 mb-lg-0">
                    <div className="card-body p-0">
                      <ul className="list-group list-group-flush rounded-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Students</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Buissness Owners Request</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Post</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Club</p>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                          <p className="mb-0">Reports</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <label><h6 style={{fontWeight: 600}}> Student Request </h6></label>
                            <img alt="#" src="images/buissness.png" width={150} height={120} />
                            <label style={{fontWeight: 600, fontSize: '11px'}}> Date : 02-OCT-2022</label>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <br />
                              Donec id elit non mi porta gravida at eget 
                              metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris 
                              condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta 
                              sem malesuada magna mollis euismod. Donec sed odio dui.
                            </p>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn btn-success">
                                  Approve
                                </button>
                              </div>
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn active btn-danger">
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <label><h6 style={{fontWeight: 600}}> Stident Request </h6></label>
                            <img alt="#" src="images/students.png" width={150} height={120} />
                            <label style={{fontWeight: 600, fontSize: '11px'}}> Date : 01-OCT-2022</label>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <br />
                              Donec id elit non mi porta gravida at eget 
                              metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris 
                              condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta 
                              sem malesuada magna mollis euismod. Donec sed odio dui.
                            </p>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn btn-success">
                                  Approve
                                </button>
                              </div>
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn active btn-danger">
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <label><h6 style={{fontWeight: 600}}> Post Approval Request </h6></label>
                            <img alt="#" src="images/post2.png" width={150} height={120} />
                            <label style={{fontWeight: 600, fontSize: '11px'}}> Date : 01-OCT-2022</label>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <br />
                              Donec id elit non mi porta gravida at eget 
                              metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris 
                              condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta 
                              sem malesuada magna mollis euismod. Donec sed odio dui.
                            </p>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn btn-success">
                                  Approve
                                </button>
                              </div>
                              <div className="col-md-6">
                                <br />
                                <br />
                                <button type="button" className="btn active btn-danger">
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}
export default SchoolAdminContent