import React from "react";

function ProductContent() {

    return(
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container px-5">
            <a className="navbar-brand" href="#!">Mercado Escolor</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link" aria-current="page" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link" href="/about">About</a></li>
                <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
                <li className="nav-item"><a className="nav-link" href="/services">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="/shop">Shop</a></li>
                <li class="nav-item">
														<a class="nav-link" href="/blog">Blog</a>
													</li>
                <li className="nav-item"><a className="nav-link active" href="#">User</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">Products</li>
                  <li className="breadcrumb-item">View Products</li>
                </ol>
              </nav>
            </div>
          </div>
          <a className="btn btn-primary btn-sm" href="#!" data-bs-toggle="modal" data-bs-target="#exampleModal">Add product</a>
          <br />
          <br />
          <div className="row">
            <table className="table align-middle mb-0 bg-white">
              <thead className="bg-light">
                <tr>
                  <th>Procut Name</th>
                  <th>Prosuct Desc</th>
                  <th>Status</th>
                  <th>In Stock</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <img src="images/book01.jpg" alt="" style={{width: '100px', height: '100px'}} />
                      <div className="ms-3">
                        <p className="fw-bold mb-1">Product One</p>
                        <p className="text-muted mb-0">Supplier001</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">It is a long established fact that a reader will be distracted by the readable </p>
                  </td>
                  <td>
                    <span className="badge badge-success rounded-pill d-inline">Active</span>
                  </td>
                  <td>100</td>
                  <td>$10</td>
                  <td>
                    <button type="button" className="btn btn-link btn-sm btn-rounded">
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
}

export default ProductContent