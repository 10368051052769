import React from "react";
import StudentContent from "./student_content";

function Student() {
    return(
        <StudentContent />
    )
}

export default Student
