import React from "react";
import AdminContent from "./admin_content";

function Admin() {

    return(
        <AdminContent />
    )
}

export default Admin
