import React from "react"
import Footer  from "../main/footer"
import HomeContent  from "./home_content"

function Home()
{
    return (
        <><HomeContent /><Footer /></>
    )
}

export default Home