import React from 'react'

function Footer() {
    return (
        <footer class="py-5 bg-dark">
            <div class="container px-4 px-lg-5"><p class="m-0 text-center text-white">Copyright © Mercado Escolor 2022</p></div>
        </footer> 
    )
}

export default Footer
