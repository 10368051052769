import React from "react";

function ServiceContent() {
    return (
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container px-5">
            <a className="navbar-brand" href="#!">Mercado Escolor</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link" aria-current="page" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link " href="/about">About</a></li>
                <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
                <li className="nav-item"><a className="nav-link active" href="#">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="/shop">Shop</a></li>
                <li class="nav-item">
                <a class="nav-link" href="http://axr2638.uta.cloud/blog/" target="_blank">Blog</a>
              </li>
                <li className="nav-item"><a className="nav-link" href="/login">Login/Register</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <header className="bg-dark py-5">
          <div className="container px-4 px-lg-4 my-4">
            <div className="text-center text-white">
              <h1 className="display-4 fw-bolder">Our Services </h1>
              <p className="lead fw-normal text-white-50 mb-0" />
            </div>
          </div>
        </header>
        <div className="row">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">IT Management</h2>
                  <br />
                  <img src="images/computer.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus 
                    quam molestias corporis quod, ea minima accusamus.</p>
                </div>
                <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">Software Development</h2>
                  <br />
                  <img src="images/coding.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Quod tenetur ex natus at dolorem enim! 
                    Nesciunt pariatur voluptatem sunt quam eaque, vel, non in id dolore 
                    voluptates quos eligendi labore.</p>
                </div>
                <div className="card-footer">
                  <a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">Product Management</h2>
                  <br />
                  <img src="images/product-chain.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus 
                    quam molestias corporis quod, ea minima accusamus.</p>
                </div>
                <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">Cloud Serives</h2>
                  <br />
                  <img src="images/computer.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus 
                    quam molestias corporis quod, ea minima accusamus.</p>
                </div>
                <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">Network Soltion</h2>
                  <br />
                  <img src="images/coding.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Quod tenetur ex natus at dolorem enim! 
                    Nesciunt pariatur voluptatem sunt quam eaque, vel, non in id dolore 
                    voluptates quos eligendi labore.</p>
                </div>
                <div className="card-footer">
                  <a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="card h-100">
                <div className="card-body">
                  <h2 className="card-title">Mobile App Devlopment</h2>
                  <br />
                  <img src="images/product-chain.png" className="d-block w-64" alt="..." />
                  <p className="card-text">Lorem ipsum dolor sit amet, 
                    consectetur adipisicing elit. Rem magni quas ex numquam, maxime minus 
                    quam molestias corporis quod, ea minima accusamus.</p>
                </div>
                <div className="card-footer"><a className="btn btn-primary btn-sm" href="#!">More Info</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    }

export default ServiceContent